.package-list-table thead {
  @apply hidden md:table-header-group;
}

.package-list-table tbody tr td {
  @apply md:border-t-2;
}

.package-list-table tbody tr td:last-of-type {
  @apply border-b-2 md:border-b-0;
}

.package-list-table tbody tr:last-of-type td:last-of-type {
  @apply border-b-0;
}

.package-list-table tbody tr:first-of-type td {
  @apply border-t-0;
}

.package-list-table tbody tr.canceled td:not(.bg-white,.bg-none) {
  @apply bg-yellow-100;
}

.package-list-table tbody tr:not(.canceled) td:not(.bg-white,.bg-none) {
  @apply bg-gb-gray-50 md:bg-inherit;
}

.package-list-table tbody tr.canceled td {
  @apply md:bg-yellow-100;
}
