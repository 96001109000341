.statements {
  @apply text-sm;
}

.statements thead {
  @apply hidden lg:table-header-group;
}

.statements tr td {
  @apply lg:border-b-2;
}

.statements tr td:last-of-type {
  @apply border-b-2;
}

.statements tr:last-of-type td {
  @apply border-b-0 lg:border-b-2;
}
