.toggle input:not(:disabled) + .label {
  @apply cursor-pointer;
}

.toggle input:disabled + .label {
  @apply opacity-50;
}

.toggle input:checked::before {
  transform: translateX(calc(100% + 5px));
}

.toggle input.large:checked::before {
  transform: translateX(calc(100% + 12px));
}
