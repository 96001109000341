.choice-input {
  @apply relative;
}

.choice-input input[type="checkbox"] {
  appearance: none;

  @apply h-4 w-4;
}

.choice-input input[type="radio"] {
  @apply hidden;
}

.choice-input input + .input-ui .icon {
  @apply hidden pointer-events-none;
}

.choice-input input[type="checkbox"] + .input-ui {
  @apply left-0 absolute pointer-events-none;
}

.choice-input input[type="checkbox"]:disabled + .input-ui,
.choice-input input[type="radio"]:disabled ~ span,
.choice-input input[type="radio"]:disabled + .input-ui,
.choice-input input[type="checkbox"]:disabled ~ div {
  @apply bg-gb-gray-300 border-gb-gray-400 opacity-50 pointer-events-none;
}

.choice-input input + .input-ui {
  @apply flex justify-center items-center h-4 w-4;
  @apply bg-white border-solid border-2 border-gb-gray-400;
}

.choice-input input[type="checkbox"] + .input-ui,
.choice-input.large input[type="radio"] + .input-ui {
  @apply rounded;
}

.choice-input input[type="checkbox"] + .input-ui .icon {
  @apply w-2;
}

.choice-input input[type="radio"] + .input-ui {
  @apply rounded-full;
}

.choice-input input[type="checkbox"]:checked + .input-ui,
.choice-input input[type="checkbox"]:indeterminate + .input-ui,
.choice-input input[type="radio"]:checked + .input-ui {
  @apply bg-gb-blue-600 border-gb-blue-600;
}

.choice-input input[type="checkbox"]:checked + .input-ui .icon.checked,
.choice-input input[type="checkbox"]:indeterminate + .input-ui .icon.indeterminate,
.choice-input input[type="radio"]:checked + .input-ui .icon.selected {
  @apply block;
}

.choice-input input[type="radio"]:checked + .input-ui {
  @apply p-1 md:p-0;
}

.choice-input input[type="radio"]:checked + .input-ui .icon.selected {
  @apply bg-white rounded-full h-full w-full md:w-2 md:h-2;
}

.choice-input.large input[type="radio"] {
  @apply py-4 px-6 border rounded;
}

.choice-input.large .box {
  @apply absolute border rounded w-full h-full left-0 top-0 transition;
}

.choice-input.large input[type="radio"]:not(:disabled) + .input-ui {
  @apply cursor-pointer;
}

.choice-input.large input[type="radio"]:checked + .input-ui {
  @apply w-6 rounded-full bg-white p-0.5;
}

.shadow-bottom {
  box-shadow: 0 4px 3px 0 rgb(51 51 51 / 7%);
}

.choice-input.large input[type="radio"]:checked + .input-ui .box {
  @apply shadow-bottom;
}

.choice-input.large:hover input[type="radio"]:not(:disabled) + .input-ui .box {
  @apply shadow-bottom opacity-100 border-black;
}

.choice-input.large input[type="radio"] + .input-ui {
  @apply w-6 h-6 rounded-full;
}

.choice-input.large input[type="radio"] + .input-ui .icon {
  @apply w-4;
}

.choice-input.large span {
  @apply text-gb-gray-900 font-semibold;
}

.choice-input.large input[type="radio"]:checked + .input-ui .icon.selected {
  @apply bg-white text-gb-blue-600 rounded-full h-4 w-4;
}
