.pager {
  @apply inline-flex border-gb-gray-400 border-solid rounded-sm text-gb-gray-800 text-sm;
}

.pager a {
  @apply h-full flex justify-center items-center px-2 bg-white min-w-8 border-2 border-gb-gray-400 text-gb-gray-700
    hover:bg-gb-blue-100;
}

.pager li:not(.next, .selected) a {
  @apply border-r-0;
}

.pager .disabled a {
  @apply text-gb-gray-400 cursor-not-allowed hover:bg-white;
}

.pager .selected a {
  @apply text-gb-gray-900 bg-gb-blue-100 border-gb-blue-600 border-r-2;
}

.pager .selected + li a {
  @apply border-l-0;
}

.pager .previous a {
  @apply rounded-l-sm;
}

.pager .next a {
  @apply rounded-r-sm;
}
