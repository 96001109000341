.issues {
  @apply text-sm;
}

.issues thead {
  @apply hidden lg:table-header-group;
}

.issues tr td {
  @apply lg:border-b-2;
}

.issues tr td:last-of-type {
  @apply border-b-2;
}

.issues tr:last-of-type td {
  @apply border-b-0 lg:border-b-2;
}
