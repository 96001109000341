.shimmer {
  position: relative;
  overflow: hidden;
}

.shimmer::after {
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  opacity: 1;
  content: "";
}

@media (prefers-reduced-motion: no-preference) {
  .shimmer::after {
    background-image:
      linear-gradient(
        90deg,
        transparent 0,
        hsl(191deg 100% 42% / 100%) calc(100% - 32px),
        white 100%
      );
    animation: shimmer 2s ease-out infinite;
  }
}

@media (prefers-reduced-motion: reduce) {
  .shimmer::after {
    background-image: none;
    background-color: hsl(191deg 100% 42% / 100%) calc(100% - 32px);
    animation: none;
  }
}

@keyframes shimmer {
  0% {
    opacity: 0.5;
  }

  90% {
    transform: translateX(32px);
    opacity: 0.5;
  }

  100% {
    transform: translateX(32px);
    opacity: 0;
  }
}
