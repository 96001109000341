.address:first-of-type {
  @apply pt-0 border-t-0;
}

.address:last-of-type {
  @apply pb-0;
}

.address {
  @apply p-2 border-t border-t-gb-gray-400;
}