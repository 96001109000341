.shopping-cart-table > thead {
  @apply hidden md:table-header-group;
}

.shopping-cart-table td[data-label]::before {
  content: attr(data-label);

  @apply table-cell font-bold w-2/3 text-left md:hidden;
}

.shopping-cart-table td[data-label] {
  @apply justify-between items-center;
}
