@media print {
  @page {
    .printable-us-letter-strict,
    .printable-us-letter-loose {
      margin: 0.25in;
      size: letter portrait;
    }

    .printable-thermal-4x6 {
      margin: 0;
      size: 4in 6in portrait;
    }
  }
}

.printable-us-letter-strict {
  @apply w-[8.5in] h-[11in] print:h-[10.5in] screen:p-[0.25in] break-after-page;
}

.printable-us-letter-loose {
  @apply w-[8.5in] screen:min-h-[11in] screen:p-[0.25in] break-after-page;
}

.printable-thermal-4x6 {
  @apply w-[4in] h-[6in] break-after-page;
}

/* 203 DPI: 812px x 1218px */