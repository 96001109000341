/* Include fonts here so we can see them in Storybook too. */
@import url("~@fontsource/poppins/latin.css");
@import url("~@fontsource/libre-barcode-39/index.css");
@import url("~react-loading-skeleton/dist/skeleton.css");
@import url("~@fortawesome/fontawesome-svg-core/styles.css");

/* Adding a comment here to redeploy with a new hash until we can get builds timestamped. */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    font-size: 16px;
  }

  body {
    @apply screen:bg-gb-gray-50 font-body font-normal leading-normal text-gb-gray-900 overflow-x-hidden max-w-[100vw];
  }

  /* We need this to hide elements that third-party JS that adds to the DOM outside of the root React app from print. */
  #root ~ * {
    @apply print:hidden;
  }
}

@layer utilities {
  .h3 {
    @apply font-semibold text-lg;
  }

  .spread-h {
    @apply flex flex-row justify-between items-center;
  }

  .center {
    @apply flex justify-center items-center;
  }

  .center-v {
    @apply flex flex-col justify-center;
  }

  .break-word {
    @apply break-normal;

    overflow-wrap: anywhere;
  }

  .border-spacing-0 {
    border-spacing: 0;
  }

  .clip-triangle {
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }

  .with-disabled-state {
    @apply disabled:opacity-50 disabled:pointer-events-none;
  }
}

@keyframes accordion-slide-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-slide-up {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
