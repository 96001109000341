.prettyDashedBorder {
  background-image: repeating-linear-gradient(0deg, #ababab, #ababab 9px, transparent 9px, transparent 19px, #ababab 19px), repeating-linear-gradient(90deg, #ababab, #ababab 9px, transparent 9px, transparent 19px, #ababab 19px), repeating-linear-gradient(180deg, #ababab, #ababab 9px, transparent 9px, transparent 19px, #ababab 19px), repeating-linear-gradient(270deg, #ababab, #ababab 9px, transparent 9px, transparent 19px, #ababab 19px);
  background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.prettyDashedBorderBlack {
  background-image: repeating-linear-gradient(0deg, #000, #000 9px, transparent 9px, transparent 19px, #000 19px), repeating-linear-gradient(90deg, #000, #000 9px, transparent 9px, transparent 19px, #000 19px), repeating-linear-gradient(180deg, #000, #000 9px, transparent 9px, transparent 19px, #000 19px), repeating-linear-gradient(270deg, #000, #000 9px, transparent 9px, transparent 19px, #000 19px);
  background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}