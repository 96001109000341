.detailsList {
  @apply grid grid-flow-row text-sm;
}

.detailsList dt {
  @apply font-bold col-span-1;
}

.borderless {
  @apply grid-cols-3 gap-4;
}

.borderless dt, .borderless dd {
  @apply p-0;
}

.borderless dd {
  @apply text-left col-span-2;
}

.borderless dt:not(:first-of-type), .borderless dd:not(:first-of-type) {
  @apply border-t-0;
}

.bordered {
  @apply grid-cols-2;
}

.bordered dd {
  @apply col-span-1 text-right;
}

.bordered dt, .bordered dd {
  @apply p-2;
}

.bordered dt:not(:first-of-type), .bordered dd:not(:first-of-type) {
  @apply border-t border-gb-gray-400;
}

.detailsList.vertical {
  @apply block;
}

.detailsList.vertical dt {
  @apply mb-1;
}

.detailsList.vertical dt, .detailsList.vertical dd {
  @apply p-0 border-0 text-left;
}

.detailsList.vertical dt:not(:first-of-type) {
  @apply mt-4;
}