dt,
dd {
  @apply p-2;
}

dt {
  @apply text-left;
}

dd {
  @apply text-right;
}

dt:not(:first-of-type),
dd:not(:first-of-type) {
  @apply border-t border-gb-gray-400;
}
