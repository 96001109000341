.react-datepicker__day {
  @apply min-w-10 min-h-10 sm:min-w-12 m-0;
}

.react-datepicker__day--outside-month {
  @apply text-gb-gray-600;
}

.react-datepicker__day--disabled:hover {
  @apply pointer-events-none;
}
