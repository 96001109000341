.timeline-list > li {
  @apply relative py-1.5 pl-5;
}

/* bullet */
.timeline-list > li::before {
  @apply block bg-gb-gray-400 rounded-full h-1.5 w-1.5 absolute left-0.5 top-1/2 -translate-y-1;

  content: "";
}

/* line */
.timeline-list > li::after {
  @apply block w-0 border-l border-gb-gray-400 absolute left-[4.5px] top-0;

  content: "";
}

/* single item */
.timeline-list > li:only-child::after {
  @apply h-1/2;
}

/* multiple items */
.timeline-list > li:not(:only-child)::after {
  @apply h-[110%] bottom-0;
}

.timeline-list > li:not(:only-child):first-child::after {
  @apply h-full top-1/2;
}

.timeline-list > li:not(:only-child):last-child::after {
  @apply h-full -top-1/2;
}
