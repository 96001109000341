.order-details thead {
  @apply hidden lg:table-header-group;
}

.order-details tr td {
  @apply lg:border-b-2;
}

.order-details tr td:last-of-type {
  @apply border-b-2;
}

.order-details tr:last-of-type td {
  @apply border-b-0 lg:border-b-2;
}
