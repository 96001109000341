.react-datepicker {
  @apply border;
  @apply border-gb-gray-400;
  @apply shadow-xl;
  @apply rounded-md;
  @apply font-body;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  @apply border-b-gb-gray-50;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  @apply border-b-gb-gray-400;
}

.react-datepicker__header {
  @apply bg-gb-gray-50;
  @apply pt-0;
  @apply pb-1;
}

.react-datepicker__day-names {
  @apply mb-0;
  @apply flex;
  @apply justify-between;
  @apply px-3;
  @apply m-0;
}

.react-datepicker__day {
  @apply w-7;
  @apply h-8;
  @apply font-normal;
  @apply text-sm;
  @apply leading-8;
  @apply text-gb-gray-900;
  @apply transition-colors;
  @apply mt-1.5;
  @apply mx-1.5;
}

.react-datepicker__day--disabled {
  @apply text-gb-gray-500;
}

.react-datepicker__day:hover {
  @apply bg-gb-gray-200;
}

.react-datepicker__day--excluded {
  @apply text-gb-gray-700;
}

.react-datepicker__day--excluded:hover {
  @apply bg-white;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-gb-gray-100;
}

.react-datepicker__day-name {
  @apply text-2xs;
  @apply text-gb-gray-800;
  @apply font-bold;
}

.react-datepicker__day--range-start {
  @apply bg-gb-blue-200;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  @apply bg-gb-blue-200;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  @apply bg-gb-blue-200;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  @apply bg-gb-blue-200;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--selected.react-datepicker__day--in-selecting-range {
  @apply bg-gb-blue-400;
}

.react-datepicker__close-icon {
  @apply right-2;
}

.react-datepicker__close-icon::after {
  @apply bg-gray-400;
  @apply text-gray-800;
}
